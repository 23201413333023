//base
@import "base/variables";
@import "base/mixins";
@import "base/icons";
@import "base/defaults";

//areas
@import "areas/header";
@import "areas/content";

//widgets
@import "widgets/loader-capuri";
@import "widgets/loader";
@import "widgets/modal";
@import "widgets/fancybox";
@import "widgets/images-cards";
@import "widgets/reordables";
@import "widgets/file-history-manager";
@import "widgets/files-choosen-modal";
@import "widgets/messages";
@import "widgets/accordion";
@import "widgets/button";
@import "widgets/dualbox";

//pages
@import "pages/login";
@import "pages/projects";
@import "pages/people";
@import "pages/companies";
@import "pages/categories";
@import "pages/last-updates";
@import "pages/contacts";
@import "pages/ata";
@import "pages/functions";
@import "pages/images";
@import "pages/project-tasks";
@import "pages/drafts";

