#backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;

    i {
        color: $c-light-brown;
        margin: 300px auto;
        font-size: 4em; }

    span {
        display: block;
        color: $c-lighter;
        top: 367px;
        position: absolute;
        margin-left: 50%;
        left: -150px;
        width: 300px;
        text-align: center; }

    > div {
        background: $c-light-brown;
        position: absolute;
        top: 400px;
        left: 50px;
        right: 50px;
        height: 5px;
        transition: 1s;

        div#progress {
            position: absolute;
            background: $c-lighter;
            transition: 1s;
            left: 0;
            height: 100%;
            width: 0%; } } }
