
.icon {
    text-indent: -99999em;
    display: inline-block;
    overflow: hidden;
    &.logo {
        @include iconic('../images/logo_icone.png', 0 0, 65px, 89px); }
    &.home, &.projects, &.contacts, &.users {
        @include iconic('../images/sprite-menu.png', 0 0, 65px, 89px); }
    &.home {
        @include bg-position(0 0); }
    &.projects {
        @include bg-position(-65px 0); }
    &.contacts {
        @include bg-position(-130px 0); }
    &.users {
        @include bg-position(-195px 0); }
    &.thumb {
        @include iconic('../images/sprite-menu-big.png', 0 0, 115px, 109px);
        &.home {
            @include bg-position(0 0); }
        &.projects {
            @include bg-position(-115px 0); }
        &.contacts {
            @include bg-position(-230px 0); }
        &.users {
            @include bg-position(-345px 0); } }
    &.close {
        @include iconic('../images/x.png', center center, 15px, 14px); }
    &.pen {
        @include iconic('../images/pen.png', center center, 20px, 20px); }
    &.mark-image {
        @include iconic('../images/mark-image.png', center center, 32px, 20px); }
    &.trash-can {
        @include iconic('../images/trash-can.png', center center, 20px, 20px); }
    &.search {
        @include iconic('../images/search.png', center center, 18px, 22px); }
    &.check {
        @include iconic('../images/check.png', center center, 17px, 17px); }
    &.cloud {
        @include iconic('../images/cloud.png', center center, 80px, 76px); }
    &.notebook {
        @include iconic('../images/notebook.png', center center, 80px, 76px); }

    &.actions {
        $sprite_size: 28px;
        &.fa.fa-bell-o {
          text-indent: 0;
          font-size: 1.2em !important; }
        &.printer {
            @include iconic('../images/sprite-actions.png', 0 center, $sprite_size, $sprite_size); }
        &.folder1 {
            @include iconic('../images/sprite-actions.png', -$sprite_size center, $sprite_size, $sprite_size); }
        &.folder2 {
            @include iconic('../images/sprite-actions.png', -($sprite_size * 2) center, $sprite_size, $sprite_size); }
        &.folder3 {
            @include iconic('../images/sprite-actions.png', -($sprite_size * 3) center, $sprite_size, $sprite_size); }
        &.folder4 {
            @include iconic('../images/sprite-actions.png', -($sprite_size * 4) center, $sprite_size, $sprite_size); }
        &.folder5 {
            @include iconic('../images/sprite-actions.png', -($sprite_size * 5) center, $sprite_size, $sprite_size); }
        &.folder6 {
            @include iconic('../images/sprite-actions.png', -($sprite_size * 6) center, $sprite_size, $sprite_size); }
        &.folder7 {
            @include iconic('../images/sprite-actions.png', -($sprite_size * 7) center, $sprite_size, $sprite_size); }
        &.folder8 {
            @include iconic('../images/sprite-actions.png', -($sprite_size * 8) center, $sprite_size, $sprite_size); }
        &.folder9 {
            @include iconic('../images/sprite-actions.png', -($sprite_size * 9) center, $sprite_size, $sprite_size); }
        &.folder10 {
            @include iconic('../images/sprite-actions.png', -($sprite_size * 10) center, $sprite_size, $sprite_size); }
        &.reorder {
            @include iconic('../images/sprite-actions.png', -($sprite_size * 13) center, $sprite_size, $sprite_size); }
        &.import-contacts {
            @include iconic('../images/sprite-actions.png', -($sprite_size * 14) center, $sprite_size, $sprite_size);
            width: 39px; } }

    &.small {
        &.executive_drawing {
            @include iconic('../images/sprite-submenu-small.png', -408px center, 55px, 43px); }

        &.private_documents {
            @include iconic('../images/sprite-submenu-small.png', -341px center, 55px, 43px); }

        &.files_initially {
            @include iconic('../images/sprite-submenu-small.png', -693px center, 55px, 43px); }

        &.technical_books {
            @include iconic('../images/sprite-submenu-small.png', -615px center, 55px, 43px); }

        &.images {
            @include iconic('../images/sprite-submenu-small.png', -145px center, 55px, 43px); }

        &.last-updates {
            @include iconic('../images/sprite-submenu-small.png', -13px center, 55px, 43px); } }

    &.sprite-private-documents {
        @include iconic('../images/sprite-submenu.png', -365px -2px, 44px, 60px); }

    &.sprite-files-initially {
        @include iconic('../images/sprite-submenu.png', -578px center, 75px, 60px);
        background-size: 800px; }

    &.sprite-executive-drawing {
        @include iconic('../images/sprite-submenu.png', -432px center, 47px, 60px); }

    &.sprite-techinal-books {
        @include iconic('../images/sprite-submenu.png', -645px -2px, 45px, 60px); }

    &.sprite-images {
        @include iconic('../images/sprite-submenu.png', -147px -4px, 66px, 60px); }

    &.sprite-contacts {
        @include iconic('../images/sprite-submenu.png', -497px -5px, 60px, 60px); }

    &.sprite-import-people {
        @include iconic('../images/sprite-submenu.png', -890px center, 70px, 60px); }

    &.sprite-people {
        @include iconic('../images/sprite-submenu.png', -76px -5px, 70px, 60px); }

    &.sprite-company {
        @include iconic('../images/sprite-submenu.png', -182px center, 50px, 60px);
        background-size: 630px; }

    &.sprite-functions {
        @include iconic('../images/sprite-submenu.png', -193px 0, 60px, 60px);
        background-size: 878px; }

    &.sprite-categories {
        @include iconic('../images/sprite-submenu.png', -566px center, 70px, 60px); }

    &.last-updates {
        @include iconic('../images/sprite-submenu.png', -12px -4px, 44px, 60px); }

    &.sprite-drafts {
        @include iconic('../images/sprite-submenu.png', -779px center, 60px, 60px); }

    &.sprite-project-schedules {
        @include iconic('../images/sprite-submenu.png', -841px -7px, 49px, 60px); }

    &.unchain {
        @include iconic('../images/icon-unchain.png', center center, 30px, 25px); }

    &.chain {
        margin-top: 1px;
        @include iconic('../images/icon-chain.png', center center, 30px, 20px); }

    &.sprite-ping-pong {
        @include iconic('../images/sprite-submenu-ping-finan.png', -64px 2px, 60px, 60px);
        background-size: 133px; }

    &.sprite-financial {
        @include iconic('../images/sprite-submenu-ping-finan.png', -8px -1px, 60px, 60px); } }
