@mixin font-default() {
    font-family: $font-default;
    font-weight: 400; }

@mixin clearfix() {
    &:after {
        display: table;
        content: "";
        clear: both; } }

@mixin button-default() {
        background: $c-light-brown;
        color: $c-light;
        padding: 5px 25px;
        border: 1px solid $c-mid-brown;
        cursor: pointer;
        font: {
            size: 12px;
            weight: bold; }
        text: {
            transform: uppercase;
            decoration: none; }
        display: inline-block; }

@mixin button-return() {
        background: $c-mid-gray-2;
        color: $c-light;
        padding: 5px 25px;
        border: 1px solid $c-mid-gray;
        cursor: pointer;
        font: {
            size: 12px;
            weight: bold; }
        text: {
            transform: uppercase;
            decoration: none; }
        display: inline-block; }

@mixin button-simple() {
    color: $c-light-brown;
    background: transparent;
    font: {
        size: 1.5em;
        weight: 700; }
    padding: 0 3%;
    border: none; }

@mixin button-iconic() {
    border: none;
    background: transparent; }

@mixin input-default() {
    input[type="text"], input[type="email"], input[type="password"], select, textarea {
        border: 1px solid $c-mid-gray;
        color: $c-dark-gray;
        padding: 0 1%; } }

@mixin multiple-checkbox() {
    ul {
        border: 1px solid $c-mid-gray;
        padding: 0.5%;
        height: 100px;
        overflow-y: auto;
        margin-bottom: 10px;
        width: 30%;
        label {
            clear: both;
            display: block;
            color: $c-mid-gray;
            line-height: 1.9em;
            font-size: 0.6em; }
        input {
            width: auto;
            float: left; } } }

@mixin input-file-button() {
    &::-webkit-file-upload-button {
        visibility: hidden; }
    &::before {
        @include button-default;
        content: 'Adicionar Arquivos';
        display: inline-block;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        position: absolute; } }

@mixin iconic($image, $position, $width, $height) {
    background: {
        image: url($image);
        position: $position;
        repeat: no-repeat; }
    width: $width;
    height: $height; }

@mixin bg-position($position) {
    background: {
        position: $position; } }

@mixin css3-prefix($property, $value) {
    -webkit-#{$property}: #{$value} {
     -khtml-#{$property}: #{$value} {
       -moz-#{$property}: #{$value} {
        -ms-#{$property}: #{$value} {
         -o-#{$property}: #{$value} {
            #{$property}: #{$value}; } } } } } }

@mixin transition($params) {
    @include css3-prefix('transition', $params); }
