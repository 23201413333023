
.content-form {
    &.people {
        h3 {
            text-align: center;
            font: {
                weight: bold;
                size: 14px; }
            margin: 20px 0;
            color: #3a3a37; }
        .user-actions {
            text-align: center !important;
            a {
                color: $c-light-brown;
                text-decoration: none;
                font: {
                    size: 1.5em;
                    weight: bold; }
                padding: 0 5%; } }

        .accord {
            height: auto;
            overflow: hidden;
            .caller {
                width: 100%;
                margin-bottom: 10px;
                height: 30px;
                line-height: 30px;
                cursor: pointer;
                font-weight: bold;
                font-size: 14px;
                color: #3a3a37;
                text-align: center; }

            .accordion-content {
                display: none;
                clear: both; }

            .permission-per-project {
                margin: 20px auto;
                label {
                    text-align: right;
                    display: inline-block; } } }


        .permissions {
            .zoom-extra-left {
                margin-left: 55px; }

            .sublist {
                display: none;
                clear: both;
                span {
                    margin-left: 20px; }
                .zoom-left {
                    margin-left: 40px; }
                .zoom-extra-left {
                    margin-left: 55px; }
                .sub-divider {
                    cursor: pointer;
                    span {
                        margin-left: 0px !important;
                        margin-right: 12px; } }
                .insidelist {
                    display: none;
                    clear: both; } }
            fieldset {
                border-bottom: 1px dashed $c-mid-gray-2; } }
        form {
            .sub-divider {
                cursor: pointer;
                span {
                    margin-left: 0px;
                    margin-right: 12px; } }
            .insidelist {
                display: none;
                clear: both; }


            .tabled-header {
                border-top: 1px dashed $c-mid-gray-2;
                border-bottom: 1px dashed $c-mid-gray-2;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 12px; }

            .table-divider {
                background-color: #ededed;
                .permission-name {
                    font-style: normal;
                    cursor: pointer;
                    span {
                        font-style: normal;
                        margin-right: 5px;
                        margin-left: 0px !important; } }
                div {
                    &:nth-child(2) {
                        text-align: center; } } }

            .permission-name {
                color: rgb(119, 119, 119);
                font-style: italic;
                font-size: 12px;
                span {
                    color: rgb(119, 119, 119);
                    font-style: italic;
                    font-size: 12px;
                    margin-left: 0px; }
                .zoom-left {
                    margin-left: 30px; }
                &.users {
                    span {
                        margin-left: 20px; } } }


            .permission-view {
                text-align: center; }

            .permission-action {
                text-align: center; } }

        #add-project-to-user {
            color: $c-light-brown;
            font-size: 10px;
            margin-left: 10px;
            display: inline-block; } } }

.zoom-left {
    margin-left: 20px; }

.zoom-notes {
    margin-left: 20px; }

.zoom-extra-left {
    margin-left: 55px; }

.sublist-padding {
    padding-left: 55px; }

.person-picture-list-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-bottom: -5px;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%; } }
