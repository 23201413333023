.main-content {
    .container {
        padding-bottom: 0; }
    .content-header {
        @include clearfix;
        border-bottom: 1px solid $c-light-brown;
        position: relative; }
    .content-header-thumbnail {
        float: left;
        padding: {
            left: 15px;
            right: 1%; }
        .thumb {
            &.home {
                $space: 23px;
                background-position:  (0 -$space) 0;
                width: 115px - $space;
                margin-right: $space; }
            &.contacts {
                $space: 6px;
                background-position: (-230px -$space) 0;
                width: 115px - $space;
                margin-right: $space; }
            &.users {
                $space: 33px;
                background-position: (-345px -$space) 0;
                width: 115px - $space;
                margin-right: $space; } } }
    .content-title {
        float: left;
        margin-top: 65px;
        text-transform: uppercase;
        color: $c-light-brown;
        font-weight: bold; }

    .submenu-navbar {
        position: absolute;
        bottom: -3px;
        right: 0;

        a {
            border-radius: 10px 10px 0 0;
            box-shadow: -2px -2px 10px -3px $c-mid-brown;
            display: inline-block;
            margin: 0;
            text-align: center;
            height: 60px;
            width: 60px;
            opacity: 0.5;
            i.fa {
                font-size: 35px;
                color: $c-capuri-gray;
                margin-top: 18px;
                opacity: 0.9; }
            &:last-child {
                margin-right: 0; }
            &.active, &:hover {
                box-shadow: 0 2px 0 0 $c-light, -2px -2px 10px -3px $c-mid-brown;
                background: $c-light;
                opacity: 1; } } }

    .content-breadcrumb {
        @include clearfix;
        padding-left: 15px;
        li {
            color: $c-dark-gray;
            font-size: 0.8em;
            line-height: 2em;
            padding: 0 5px 0 0;
            float: left;
            &:first-child {
                &:before {
                    content: ''; } }
            &:before {
                font-size: 0.9em;
                content: '\00BB';
                padding-right: 5px; }
            a {
                text-decoration: none;
                color: $c-light-brown;
                &:hover {
                    text-decoration: underline; } } } }
    .content-area {
        background-color: $c-lighter;
        .content-area-header {
            $height: 35px;
            height: $height;
            background-color: $c-light-brown;
            .content-area-title, .content-area-title-category, .content-area-title-subcategory, .content-area-title-zoomcategory {
                color: $c-lighter;
                font-weight: bold;
                line-height: $height;
                display: inline-block;
                padding-left: 40px; }

            .content-area-title-category, .content-area-title-subcategory, .content-area-title-zoomcategory {
                padding-left: 0;
                display: none;
                &:before {
                    content: '\00BB'; } }


            .updates {
                background: url('../images/titles/ico-updates.png') no-repeat 8px center; }

            .project {
                background: url('../images/titles/ico-projects.png') no-repeat 8px center; }

            .private-documents {
                background: url('../images/titles/ico-private-documents.png') no-repeat 8px center; }

            .files-initially {
                background: url('../images/titles/ico-files-initially.png') no-repeat 8px center; }

            .executive-drawing {
                background: url('../images/titles/ico-executive-drawing.png') no-repeat 8px center; }

            .technical-books {
                background: url('../images/titles/ico-technical-books.png') no-repeat 8px center; }

            .images {
                background: url('../images/titles/ico-images.png') no-repeat 8px center; }

            .companies {
                background: url('../images/titles/ico-companies.png') no-repeat 8px center; }

            .contacts {
                background: url('../images/titles/ico-contacts.png') no-repeat 8px center; }

            .functions {
                background: url('../images/titles/ico-functions.png') no-repeat 8px center; }

            .categories {
                background: url('../images/titles/ico-categories.png') no-repeat 8px center; }

            .people {
                background: url('../images/titles/ico-people.png') no-repeat 8px center; }

            .drafts {
                background: url('../images/titles/ico-drafts.png') no-repeat 8px center; }

            .chrono {
                background: url('../images/titles/ico-chrono.png') no-repeat 12px center;
                background-size: 14px; }

            .finantial {
                background: url('../images/titles/ico-finantial.png') no-repeat 10px center;
                background-size: 24px; }

            .ping-pong {
                background: url('../images/titles/ico-ping-pong.png') no-repeat 5px center;
                background-size: 30px; } }

        .text-permission {
            color: $c-light-brown;
            display: block;
            text-align: center;
            font-size: 25px; }


        .content-area-subtitle {
            font-size: 0.8em;
            display: block;
            clear: both;
            line-height: 1.6em;
            color: $c-mid-gray-2; }
        .content-area-actions {
            float: right;
            text-align: center !important;
            white-space: nowrap;
            height: 100%;
            padding: 0 10px;
            &:before {
                content: '';
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                margin-right: -0.25em; }
            &.floated {
                position: absolute;
                top: -48px;
                right: -12px;
                height: 35px; }
            a {
                color: $c-lighter;
                text-decoration: none;
                display: inline-block;
                vertical-align: middle; }
            a[data-mass-actions] {
                display: none; }
            i.fa {
                font-size: 1.5em; }
            .icon-bell {
                background-image: url("../images/icon_enviar_notificacao.png");
                background-size: contain;
                height: 21px;
                width: 20px;
                display: inline-block; } }
        .content-sidebar {
            width: 25%;
            max-width: 300px;
            float: left;
            position: relative;
            height: 100%;

            &.inputable {
                width: 40%;
                li {
                    &.active {
                        span {
                            background: none; } } } }


            > li {
                &:before {
                    padding-right: 10px; } }

            li {
                position: relative;
                text-decoration: none;
                color: $c-light-brown;
                font: {
                    size: 10px;
                    weight: bold; }
                line-height: 3em;
                padding: 0.5% 0 0.5% 1%;
                border: {
                    top: 1px dashed #ccc; }
                cursor: pointer;
                opacity: 0.7;
                margin-left: 10px;

                li span {
                    width: 150px;
                    line-height: 3em;
                    display: inline-block; }

                &.has_files {
                    opacity: 1; }

                &:before {
                    content: '+ ';
                    font-size: 12px;
                    color: $c-light-brown;
                    position: absolute;
                    margin-left: -10px; }

                &.active {
                    > span {
                        background-color: #ddd;
                        line-height: 3em;
                        display: inline-block; }


                    &:before {
                        content: "- "; }
                    > ul {
                        @include transition(2s cubic-bezier(.63,.38,.27,.69));
                        max-height: 100%; } }
                &.special {
                    color: $c-mid-dark-gray;
                    line-height: 6em;
                    > span {
                        line-height: 6em;
                        width: 100%; }
                    &:before {
                        color: $c-mid-dark-gray; } }

                &.sub-false {
                    &:before {
                        content: ' ';
                        padding-left: 10px; } }
                ul {
                    @include transition(0.2s cubic-bezier(0,.02,0,.81));
                    display: block;
                    max-height: 0px;
                    overflow: hidden; }
                li {
                    padding-left: 7%;
                    padding-right: 0; }

                .is_image {
                    position: absolute;
                    right: 0;
                    top: 0;
                    a {
                        margin: 0 2px; }
                    i {
                        margin: 3px 0 0; } } } }

        .content-main-with-sidebar {
            width: 75%;
            float: right;
            position: relative; } }

    .content-area-main {
        @include clearfix;
        padding: 13px 1.5%; }

    .content-form {
        @include input-default;
        .file-images, .file-description {
            input[type="text"], textarea {
                width: 100%; } }
        input[type="text"], input[type="password"], input[type="email"], select {
            height: 20px; }
        input[type="date"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            display: none; }
        input[type="date"]::-webkit-calendar-picker-indicator {
            -webkit-appearance: none;
            display: none; }
        select[multiple="multiple"] {
            height: auto; }
        label {
            font-size: 0.8em;
            input[type="checkbox"] {
                float: left;
                margin-right: 5px; }
            &.required {
                &:after {
                    content: " * ";
                    color: #ff1111; } } }
        .error_notice {
            font-size: 0.6em;
            margin-left: 1em;
            color: $c-light-brown; }

        span, a {
            color: $c-dark-gray;
            word-break: break-all; }

        a {
            text-decoration: none;
            color: $c-light-brown;
            &:hover {
                text-decoration: underline; } }
        h4 {
            width: 100%;
            color: $c-light-brown;
            margin: {
                top: 20px;
                bottom: 20px; }
            border: {
                bottom: 1px dashed $c-mid-gray; }
            font: {
                size: 14px;
                weight: bold; } }
        hr {
            &.borded {
                border: {
                    top: none;
                    left: none;
                    right: none;
                    bottom: 1px dashed $c-lighter-gray; } }
            &.spacer {
                display: inline;
                margin: 0 15px 0 10px;
                font-size: 1em;
                border: {
                    top: none;
                    right: none;
                    bottom: none;
                    left: 1px solid $c-light; } } }
        .multiple-checkbox, .file-attendees {
            @include multiple-checkbox; }
        .multiple-checkbox {
            ul {
                float: left;
                width: 20%; } }
        .contact-multiple-checkbox {
            ul {
                float: left;
                width: 41%;
                height: 300px; }
            #filter-contacts {
                margin-bottom: 10px;
                label {
                    font-size: 12px;
                    color: $c-mid-gray-2;
                    cursor: pointer; } } }
        .file-attendees {
            ul {
                float: left; } }

        .multiple-images {
            float: left;
            font-size: 0.6em;
            line-height: 0em;
            color: $c-mid-gray;
            border: 1px solid $c-mid-gray;
            width: 100%;
            padding: 0.5% 10px;
            max-width: 220px;
            height: 90px;
            overflow-y: auto;
            i {
                float: left; } }

        .tabled-header {
            div, th {
                color: $c-mid-gray-2; }
            th {
                background: url(../lib/tablesorter/themes/blue/bg.gif) no-repeat center right;
                cursor: pointer; }
            th.headerSortDown {
                background-image: url(../lib/tablesorter/themes/blue/desc.gif); }
            th.headerSortUp {
                background-image: url(../lib/tablesorter/themes/blue/asc.gif); } }

        table.tabled {
            display: table; }
        .tabled {
            display: block;
            width: 100%;
            margin-bottom: 10px;
            table {
                width: 100%; } }
        .tabled-row {
            width: 100%;
            padding: 5px 0;
            border-bottom: 1px dashed $c-light-gray;
            &.file-content-dated {
                border: none;
                fieldset, tr {
                    white-space: nowrap;
                    height: 100%;
                    color: $c-mid-dark-gray;
                    padding: 0px;
                    border: {
                        bottom: none; }
                    &:before {
                        content: '';
                        display: inline-block;
                        height: 100%;
                        vertical-align: middle;
                        margin-right: -0.25em; }
                    .icon {
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 10px 0 0px; }
                    .file-description {
                        color: $c-mid-dark-gray; } }
                .item-list {
                    width: 100%;
                    margin-bottom: 20px;
                    margin-bottom: 30px;
                    .file-description {
                        a {
                            color: $c-mid-dark-gray;
                            font-style: italic;
                            margin-bottom: 20px; } } } }
            > div, > td, > th {
                display: block;
                float: left;
                padding: 3px;
                &:last-child {
                    float: right;
                    padding-right: 0; }
                &:first-child {
                    float: left;
                    padding: 3px 5px 3px; }
                &.file-actions, &.user-actions {
                    background-image: none; } }
            fieldset, tr {
                border-bottom: 1px dashed #ccc; }
            img {
                max-height: 70px;
                width: auto;
                max-width: 100%;
                margin: 0 auto; } }

        .label-style {
            text-align: right;
            padding-right: 10px;
            font: {
                size: 0.7em;
                weight: bold; }
            color: $c-mid-gray;
            display: block;
            width: 100%;
            max-width: 140px;
            float: left;
            line-height: 20px;
            margin-right: 5px;
            border-top: 1px dashed #ddd; }

        form {
            > fieldset, > tr {
                > input, > select, > textarea {
                    float: left;
                    width: 100%;
                    max-width: 220px;
                    margin-bottom: 10px; }
                > label {
                    @extend .label-style; }
                .file-field, .contact-picture {
                    margin-bottom: 10px;
                    display: block;
                    line-height: 21px;
                    float: left;
                    font-size: 12px; }
                .contact-picture {
                    display: flex;
                    margin: 5px;

                    .contact-picture-thumb {
                        margin-right: 5px;
                        img {
                            max-width: 200px;
                            max-height: 100px; } } } } }

        .file-extensions {
            margin-top: 5px;
            display: block;
            a {
                cursor: pointer; } }


        &.listed {
            .file-others {
                > label {
                    font-weight: normal; } }
            span, a {
                font-size: 0.8em; }

            .tabled-header {
                div, th, td {
                    font-size: 0.8em;
                    text-align: left; } } }
        &.aligned-buttons {
            margin-bottom: -38px; } } }

.main-footer {
    margin-bottom: 10px;
    .container {
        @include clearfix;
        background-color: $c-lighter;
        padding: 0 1.5% 1%; } }

div.ui-datepicker {
    font-size: 11px; }

.divider {
    display: none;
    color: $c-lighter; }
