.content-form {
    &.functions {
        .tabled-header {
            font-weight: bold;
            color: $c-mid-dark-gray; }

        .accord {
            &:hover {
                background: rgba(232, 232, 232, 0.5); }
            .caller {
                background: url(../../images/plus_red.gif) 0 center no-repeat;
                display: block;
                font-weight: bold;
                font-size: 12px;
                color: $c-mid-dark-gray;
                cursor: pointer;
                padding: 10px 0 10px 15px;
                &.open {
                    background: url(../../images/minus_red.gif) 0 center no-repeat; } }
            .list {
                display: none;
                &.active {
                    display: block; }
                li {
                    padding-left: 18px;
                    line-height: 1.5em;
                    a {
                        font-size: 12px;
                        color: $c-mid-dark-gray; } } } }
        .file-actions {
            text-align: center !important;
            a {
                color: $c-light-brown;
                text-decoration: none;
                font: {
                    size: 1.5em;
                    weight: bold; }
                padding: 0 5%; } } } }
