.main-header {
    .container {
        position: relative;

        .plot-img {
            background: url(../images/plot_text.jpg);
            width: 62px;
            height: 29px;
            right: 24px;
            top: 24px;
            position: absolute; }

        .slot-img {
            background: url(../images/slot_text.jpg) no-repeat center center $c-light;
            width: 65px;
            height: 26px;
            right: 86px;
            top: 46px;
            position: absolute; } }

    .main-header-bar {
        position: relative;
        border-bottom: 1px solid $c-mid-gray;
        @include clearfix;

        a {
            display: inline-block; }

        &::before {
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            position: absolute;
            left: -3px;
            bottom: -2.5px;
            background: $c-mid-gray; }

        &::after {
            content: url(../images/pen_header.png);
            width: 41px;
            height: 40px;
            position: absolute;
            right: -39px;
            bottom: -4px; } }


    .menu-navbar {
        float: left;
        min-width: 500px;
        a {
            float: left;
            display: block;
            padding-bottom: 5px;
            margin-left: 16px;
            &.active, &:hover {
                box-shadow: 0 3px 0 0 $c-light-brown; } } }
    .logo {
        float: left;
        height: 52px;
        margin-bottom: -5px;
        margin-left: 24px;
        cursor: pointer; }

    .logo-capuri {
        font: 400 25px Arial;
        color: $c-mid-gray;
        letter-spacing: -1.2px;
        margin: 15px 0 -20px 10px;
        display: inline-block;
        text-transform: uppercase; }

    .login-area, .menu-links, .menu-links ul {
        @include clearfix; }

    .menu-links {
        display: inline-block;
        position: relative;

        &.hide {
            visibility: hidden;
            opacity: 0;
            top: -15px;
            transition: all .3s ease-out; }

        &.show {
            visibility: visible;
            opacity: 1;
            top: 0;
            transition: all .3s ease-in; }

        ul {
            padding: 0;
            margin: 0;
            margin: 10px 0 0 13px;
            display: inline-block;

            li {
                list-style: none;
                display: inline-block;
                margin: 0 10px;
                &:first-child {
                    margin-left: 0; }
                &:last-child {
                    margin-right: 0; }
                a {
                    font: 400 14px Arial;
                    text-transform: uppercase;
                    color: $c-mid-gray;
                    text-decoration: none;
                    &.active, &:hover {
                        color: $c-mid-brown; } } } } }

    .login-area {
        float: right; }
    .logged {
        padding: 7px 0;
        float: right;
        font-size: 0.8em;
        >img {
            margin-left: 5px;
            margin-top: 4px;
            height: 10px;
            cursor: pointer; }
        a {
            text-decoration: none;
            color: $c-dark; }
        svg {
            margin-left: 5px; }
        .logged-greeting {
            display: block;
            position: relative;
            float: left;
            padding-top: 2px;
            .userOptions {
                display: none;
                left: -46px;
                position: absolute;
                border: 1px solid $c-lighter;
                top: 33px;
                background-color: $c-lighter;
                height: 60px;
                width: 110px;
                z-index: 10;
                padding-bottom: 10px;
                &.active {
                    display: block;
                    border-top: none; }
                div {
                    width: 100%;
                    height: 50%;
                    padding: 10px 11px 5px 12px;
                    a {
                        font-size: 13.5px;
                        text-decoration: none;
                        color: $c-dark;
                        display: block; } } }
            .arrowUp {
                display: none;
                position: absolute;
                z-index: 2;
                left: -46px;
                width: 110px;
                top: 8px;
                &.active {
                    display: block; }
                img {
                    top: 10px;
                    height: 15px;
                    position: absolute;
                    left: 43px; } } }
        .icon.close {
            height: 11px; }

        .greeting-profile-picture {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            overflow: hidden;
            display: inline-block;
            margin-bottom: -5px;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%; } } }

    .logout {
        .icon {
            float: right;
            margin-right: 5px; } } }





