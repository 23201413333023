.main-content {
    .file-content-dated {
        &:first-child {
            .file-date {
                margin-top: 0px; } }
        &.tabled-row {
            padding: 0; }
        .file-date {
            font: {
                size: 0.9em;
                weight: bold; }
            color: $c-light-brown;
            border: {
                bottom: 1px dashed $c-light-gray; }
            margin: {
                top: 10px; }
            padding: 30px 0 5px; }
        .file-description {
            font: {
                size: 0.8em;
                style: italic; }
            color: $c-dark-gray;
            a {
                color: $c-light-brown;
                text-decoration: none;
                font-style: normal;
                &:hover {
                    text-decoration: underline; } } }
        fieldset {
            padding: 10px 0; } } }
