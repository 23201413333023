.content-form {
    &.companies {
        .tabled-header {
            font-weight: bold;
            color: $c-mid-dark-gray; }
        .file-actions {
            text-align: center !important;
            a {
                color: $c-light-brown;
                text-decoration: none;
                font: {
                    size: 1.5em;
                    weight: bold; }
                padding: 0 5%; } } } }
