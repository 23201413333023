.accordion-wrapper {
    height: auto;
    overflow: hidden;
    .caller {
        width: 100%;
        margin-bottom: 10px;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        font-weight: bold;
        font-size: 14px;
        color: #3a3a37;
        text-align: center; }

    .accordion-content {
        display: none;
        clear: both; } }
