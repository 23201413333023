.content-form {
    &.contacts {
        .contact-info {
            @import "../widgets/dualbox";
            position: relative;

            .floatbox {
                float: right;
                width: 30%;
                max-width: 300px;
                .floatbox-header {
                    background-color: $c-light-brown;
                    line-height: 30px;
                    padding: 0 2%;
                    color: $c-lighter;
                    font-size: 0.9em;
                    font-weight: bold; }
                .floatbox-list {
                    border: {
                        left: 1px solid $c-dark-gray;
                        right: 1px solid $c-dark-gray;
                        bottom: 1px solid $c-dark-gray; }
                    li {
                        line-height: 25px;
                        font-size: 0.8em;
                        padding: 0 1%; } } }

            .floatbox-left {
                float: left;
                margin-left: 90px; }

            .contact-block {
                clear: both;
                margin-bottom: 50px;
                .wrap {
                    @include clearfix;
                    .field, .fields {
                        height: 23px;
                        line-height: 23px;
                        margin-bottom: 5px;
                        display: block;
                        color: $c-mid-dark-gray; }
                    .field {
                        font-size: 0.8em; }
                    .fields {
                        li {
                            display: inline;
                            .field {
                                display: inline;
                                font-size: 0.8em;
                                color: $c-mid-dark-gray; } } } }

                .contact-details {
                    display: flex;

                    .the-details {
                        flex-grow: 1; }

                    .contact-picture-view {
                        img {
                            max-width: 400px;
                            max-height: 200px; } } } } }

        .file-channel {
            label {
                text-align: right;
                padding-right: 10px;
                font-size: 0.7em;
                font-weight: 700;
                color: $c-mid-gray;
                display: block;
                width: 100%;
                max-width: 140px;
                float: left;
                line-height: 20px;
                margin-right: 5px;
                border-top: 1px dashed #DDD; }

            select, input {
                float: left;
                width: 100%;
                max-width: 220px;
                margin-bottom: 10px;
                margin-right: 10px; }

            .add, .del {
                font-size: 20px;

                &:hover {
                    text-decoration: none; } } }

        .contact-search {
            white-space: nowrap;
            height: 100%;
            padding-bottom: 10px;
            margin-bottom: 20px;

            &:before {
                content: '';
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                margin-right: -0.25em; }

            .icon {
                display: inline-block;
                vertical-align: middle; } }

        .tabled.box {
            .tabled-row {
                border: 1px solid $c-mid-gray;
                padding: 1%;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0; } } }

        .contact-person-info, .contact-company-info, .contact-picture-list-view, .contact-actions {
            float: left;

            span, ul, li {
                font-size: 0.8em;
                line-height: 1.5em;
                b, li {

                    font-weight: bold; } }
            ul, li {
                display: inline;
                font-size: 1em; }

            li {
                &:first-child {
                    &:before {
                        content: ""; } }

                &:before {
                    content: " | "; } }

            span {
                display: block;

                .noinformations {
                    display: inline-block; } }

            .contact-email a {
                color: $c-dark-gray;
                text-decoration: none; } }

        .contact-person-info {
            width: 40%; }

        .contact-company-info {
            width: 40%; }

        .contact-picture-list-view {
            width: 10%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain; } }

        .tabled-row {
            .contact-actions {
                width: 10%;
                text-align: right;
                padding-right: 0;

                a {
                    margin: 0 5%;
                    text-decoration: none; } } }

        #import-contacts-list {
            margin-top: 10px;

            .import-channel-info {
                &:hover {
                    background-color: rgba(0,0,0,0.1); }

                td {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    height: 30px; } } } } }
