#login {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    form {
        $height: 90px;
        $width: 320px;
        max-width: $width;
        height: $height;
        font-size: 1em;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin: {
            top: - $height;
            left: auto;
            right: auto; } }

    a {
        font-weight: normal;
        text-decoration: none;
        color: $c-light-brown;
        font-size: 12px;
        line-height: 30px;
        &:hover {
            text-decoration: underline; } }

    .login-username, .login-password {
        @include input-default;
        margin-bottom: 10px;
        label, input {
            display: block;
            float: left; }
        label {
            cursor: pointer;
            line-height: 20px;
            width: 20%;
            font-size: 0.75em; }
        input {
            height: 20px;
            width: 70%; } }

    .login-password {
        margin-bottom: 30px; }

    .login-enter {
        @include button-default;
        float: right;
        margin-right: 30px; }

    .error_notice {
        display: block;
        clear: both;
        margin: 0px;
        position: absolute;
        bottom: 20px;
        right: 76px;
        font-size: 11px; } }
