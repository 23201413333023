.printable {
    @media print {
        * {
            -webkit-print-color-adjust: exact !important; } } }
project-tasks {
    display: block;
    font-size: 12px;
    text-align: center;
    width: 100%;

    > p.loading-project {
        padding: 20px 0; }

    .spinner {
        margin: auto;
        padding: 100px 0; }

    @media print {
        * {
            -webkit-print-color-adjust: exact !important; } } }
