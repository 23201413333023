.button-default {
    @include button-default;

    &.left {
        float: left; }

    &.right {
        float: right; } }

.button-clean {
    @include button-return; }

.button-file {
    input[type="file"] {
        @include input-file-button; } }

.button-simple {
    @include button-simple; }

.button-iconic {
    @include button-iconic; }



.action-back {
    background: transparent;
    color: #aaa;
    padding: 5px 0;
    border: none;
    cursor: pointer;
    font: {
        size: 16px;
        weight: bold; }
    text: {
        transform: uppercase;
        decoration: none; }
    display: inline-block;
    float: left;
    position: relative;
    z-index: 1; }
