.main-content .content-form {
    .treated-subject-row {
        .file-actions {
            display: flex;
            justify-content: space-around;
            width: 80px; } }

    .atas-listing {
        .file-name-linked a {
            display: flex;
            align-items: center;

            img.ata-is-closed {
                width: 20px;
                margin: 0; } } }

    a.action-add {
        display: block;
        widht: 50px;
        text-align: right;
        font-weight: bold;
        font-size: 1em;
        margin-right: 20px; } }


.description-ata {
    width: 250px; }

input[name="finalizar"] {
    margin-right: 20px; }
