.capuri-loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    width: 180px;
    height: 50px;
    margin: -25px auto 0;
    border: 1px solid $c-light-brown;
    z-index: 9;
    box-shadow: 0px 0px 3px 0px $c-light-brown;
    display: none;

    &:before {
        content: '';
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        display: block;
        top: 0;
        left: 0;
        z-index: 8; }

    .spin-wrapper {
        height: 100%;
        overflow: hidden;
        background-color: $c-lighter;
        font: {
            size: 0.8em; }
        color: $c-light-brown;
        position: relative;
        z-index: 9;
        box-sizing: border-box;
        padding: 12px 15px;

        .spin {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            border-top: .25rem solid $c-light-brown;
            border-left: .25rem solid rgba($c-light-brown, 0.2);
            border-right: .25rem solid rgba($c-light-brown, 0.2);
            border-bottom: .25rem solid rgba($c-light-brown, 0.2);
            animation: spinner 1s infinite linear;
            float: left; }

        p {
            float: left;
            font-size: 16px;
            line-height: 28px;
            margin-left: 15px; }

        @keyframes spinner {
            0% {
                transform: rotate(0deg); }
            100% {
                transform: rotate(360deg); } } } }
