* {
    box-sizing: border-box; }

body {
    @include font-default;
    color: $c-dark;
    background: $c-light;
    font-size: 1em; }

.hidden, .hide {
    display: none; }

.transparent {
    opacity: 0; }

.container {
    width: 90%;
    padding: 10px 0;
    margin: {
        left: auto;
        right: auto; }
    min-width: 1024px;
    .container {
        width: 100%;
        padding: 0; } }
.word-wrap {
    word-wrap: break-word; }

.col1, [data-col="1"] {
    width: $col_min_size; }
.col2, [data-col="2"] {
    width: ($col_min_size * 2); }
.col3, [data-col="3"] {
    width: ($col_min_size * 3); }
.col4, [data-col="4"] {
    width: ($col_min_size * 4); }
.col5, [data-col="5"] {
    width: ($col_min_size * 5); }
.col6, [data-col="6"] {
    width: ($col_min_size * 6); }
.col7, [data-col="7"] {
    width: ($col_min_size * 7); }
.col8, [data-col="8"] {
    width: ($col_min_size * 8); }
.col9, [data-col="9"] {
    width: ($col_min_size * 9); }
.col10, [data-col="10"] {
    width: ($col_min_size * 10); }
.col11, [data-col="11"] {
    width: ($col_min_size * 11); }
.col12, [data-col="12"] {
    width: ($col_min_size * 12); }
.col13, [data-col="13"] {
    width: ($col_min_size * 13); }
.col14, [data-col="14"] {
    width: ($col_min_size * 14); }
.col15, [data-col="15"] {
    width: ($col_min_size * 15); }
.col16, [data-col="16"] {
    width: ($col_min_size * 16); }

.label-name {
    display: block;
    margin: 0;
    padding: 0;
    line-height: normal;
    small {
        font-size: 90%; }
    strong {
        font-weight: bold; } }
