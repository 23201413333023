.messages {
    &.lightbox {
        position: fixed;
        left: 0;
        right: 0;
        top: 20%;
        width: 60%;
        margin: 0 auto;
        border: 1px solid $c-light-brown;
        z-index: 9;
        box-shadow: 0px 0px 3px 0px $c-light-brown;
        li {
            padding: 10px 5%;
            background-color: $c-lighter;
            font: {
                size: 0.8em; }
            color: $c-light-brown;
            position: relative;
            z-index: 9; }
        &:before {
            content: '';
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.3);
            display: block;
            top: 0;
            left: 0;
            z-index: 8; } } }

.noinformations {
    font-style: italic;
    color: #aaa !important; }

.error_notice {
    font-size: 0.6em;
    margin-left: 1em;
    color: $c-light-brown; }
