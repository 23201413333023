#images-table {
    label[for="toggle-all-checkboxes"] {
        display: none; }

    .tabled-row {
        .card-background {
            display: none; } }

    &.as-cards .tabled-row {
        &.tabled-header {
            border: none;
            width: 100%;
            height: 30px;
            margin: -22px 0 0 10px;
            box-shadow: none;

            &:hover {
                box-shadow: none; }

            > div:not(.file-check) {
                display: none; }

            > div.file-check {
                width: 100%;

                input {
                    margin: 0;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0; }

                label {
                    font-size: 12px;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 20px;
                    cursor: pointer; } } }

        position: relative;
        padding: 0;
        display: block;
        width: 250px;
        height: 280px;
        margin: 10px;
        float: left;
        border: none;
        background: $c-lighter;
        overflow: hidden;
        border-radius: 3px;
        box-shadow: 0 3px 9px -2px rgba(0, 0, 0, 0.6);

        &:hover {
            box-shadow: 0 3px 11px -1px rgba(0, 0, 0, 0.6); }

        .card-background {
            display: block;
            position: absolute;
            width: 100%;
            height: 80%;
            z-index: 0;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 0; }

        .file-check {
            position: absolute;
            left: 10px;
            top: 10px;
            z-index: 1; }

        .file-name-linked {
            display: block;
            position: absolute;
            width: 100%;
            bottom: 20%;
            height: 80%;
            padding: 0;

            a {
                display: block;
                color: $c-lighter;
                position: absolute;
                width: 100%;
                height: 100%;

                .subtitle {
                    position: absolute;
                    background: rgba(0, 0, 0, 0.5);
                    display: block;
                    bottom: 0;
                    padding: 20px;
                    width: 100%; } }


            img {
                display: none; } }

        .file-description {
            display: block;
            position: absolute;
            top: 80%;
            width: 50%;
            padding: 10px; }

        .file-actions {
            display: block;
            position: absolute;
            top: 80%;
            padding: 17px 10px 10px 0;
            right: 0;
            width: 50%; } } }
