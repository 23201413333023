.fancybox-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0; }

.fancybox-skin {
    .fancybox-item.fancybox-close {
        position: absolute;
        top: 0;
        right: 10px;
        z-index: 20;
        width: 26.4062px;
        cursor: pointer;
        background-image: none; }

    .fancybox-nav {
        &.fancybox-next {
            position: absolute;
            cursor: pointer;
            top: 50%;
            z-index: 1;
            height: 50px;
            width: 50px;
            margin-top: -25px;
            display: flex;
            justify-content: center;
            align-items: center; }

        &.fancybox-prev {
            position: absolute;
            cursor: pointer;
            top: 50%;
            z-index: 1;
            height: 50px;
            width: 50px;
            margin-top: -25px;
            display: flex;
            justify-content: center;
            align-items: center; } }

    .caption {
        font-size: 16px;
        line-height: 24px;
        color: #9b4128; } }

.fancybox-wrap {
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%); }

.fancybox-wrap {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }

.fancybox-overlay {
    overflow-x: hidden;
    overflow-y: auto; }

.fancybox-wrap {
    position: absolute;
    width: 90% !important;
    height: 90% !important;
    left: 5% !important;
    margin: 30px auto 0;
    top: 0px !important; }

.fancybox-skin {
    background-color: #d1d1d1 !important;
    -webkit-background-clip: padding-box;
    background-clip: padding-box !important;
    border: 1px solid #d1d1d1 !important;
    outline: 0 !important;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5) !important;
    position: relative !important;
    padding: 15px 0 !important;
    height: 100% !important; }
.fancybox-outer {
    position: relative;
    margin: 0 auto;
    z-index: 12;
    height: 100%;
    .fancybox-inner {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        padding: 0 45px;
        text-align: center;
        img {
            max-height: 93%;
            max-width: 100%;
            width: auto !important;
            height: auto !important;
            display: initial !important;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto; } }

    .embed-responsive {
        width: 100%;
        height: 100%;
        max-height: 95%;
        embed-video {
            height: 100% !important; }
        iframe {
            width: 90%;
            height: inherit; } } }

.fancybox-title.fancybox-title-float-wrap {
    bottom: 10px;
    margin-bottom: 0;

    span.child {
        text-shadow: none;
        background: transparent;
        border: 0;
        color: #555;
        font-weight: normal; } }
