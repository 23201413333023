$c-lighter: #fff;
$c-light: #f0f0f0;
$c-dark: #000000;

$c-dark-gray: #333333;
$c-mid-gray-2: #999999;
$c-mid-dark-gray: #777777;
$c-mid-gray: #adadad;
$c-light-gray: #cccccc;
$c-lighter-gray: #dfdfdf;
$c-capuri-gray: #82745E;

$c-light-brown: #ae6b49;
$c-mid-brown: #8e4c32;

$font-sans: sans-serif;
$font-default: 'Arial', $font-sans;

$col_min_size: 6.25%;
