.selector {
    width: 80%;
    float: left; }

.selector select {
    width: 220px;
    height: 200px; }

.selector-available, .selector-chosen {
    float: left;
    width: 220px;
    text-align: center;
    margin-bottom: 5px; }

.selector-chosen select {
    border-top: none; }

.selector-available h2, .selector-chosen h2 {
    line-height: 30px;
    background: $c-light-brown;
    color: $c-lighter;
    font-size: 14px; }

.selector .selector-available h2 {
    line-height: 30px;
    background: $c-light-brown;
    color: $c-lighter;
    font-size: 14px; }

.selector .selector-filter {
    background: white;
    border: 1px solid #ccc;
    border-width: 0 1px;
    padding: 3px;
    color: $c-mid-gray-2;
    font-size: 10px;
    margin: 0;
    text-align: left; }

.selector .selector-filter label img {
    position: relative;
    float: left;
    margin-top: 2px; }

.selector .selector-filter label,
.inline-group .aligned .selector .selector-filter label {
    width: 16px;
    padding: 2px; }

.selector .selector-available input {
    width: 185px; }

.selector ul.selector-chooser {
    float: left;
    width: 22px;
    height: 46px;
    background-color: #eee;
    border-radius: 10px;
    margin: 116px 5px 0 5px;
    padding: 0; }

.selector-chooser li {
    margin: 0;
    padding: 3px;
    list-style-type: none; }

.selector select {
    margin-bottom: 10px;
    margin-top: 0; }

.selector-add, .selector-remove {
    width: 16px;
    height: 16px;
    display: block;
    text-indent: -3000px;
    overflow: hidden; }

.selector-add {
    background: url(../../images/selector-icons.gif) 0 -161px no-repeat;
    cursor: default;
    margin-bottom: 2px; }

.active.selector-add {
    background: url(../../images/selector-icons.gif) 0 -187px no-repeat;
    cursor: pointer; }

.selector-remove {
    background: url(../../images/selector-icons.gif) 0 -109px no-repeat;
    cursor: default; }

.active.selector-remove {
    background: url(../../images/selector-icons.gif) 0 -135px no-repeat;
    cursor: pointer; }

a.selector-chooseall, a.selector-clearall {
    display: inline-block;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    color: $c-light-brown;
    font-size: 14px;
    margin-bottom: 25px; }

a.selector-chooseall {
    cursor: default; }

a.active.selector-chooseall {
    cursor: pointer; }

a.selector-clearall {
    cursor: default; }

a.active.selector-clearall {
    cursor: pointer; }
