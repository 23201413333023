.file-history-manager {
    position: relative;
    display: flex;
    padding: 5px 5px 0 0;
    margin-top: 3px; }

.file-save {
    @include button-default;
    margin-top: 20px;
    float: right; }
