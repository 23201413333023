.list-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    width: 400px;
    height: 400px;
    margin: -200px auto 0;
    border: 1px solid $c-light-brown;
    z-index: 9;
    box-shadow: 0px 0px 3px 0px $c-light-brown;
    display: none;
    .list-modal-wrapper {
        position: relative;
        height: 100%;
        overflow: hidden;
        background-color: $c-lighter;
        font: {
            size: 0.8em; }
        color: $c-light-brown;
        position: relative;
        z-index: 9;
        .header {
            position: absolute;
            top: 0;
            padding: 10px 0;
            background: $c-light-brown;
            color: $c-lighter;
            height: 60px;
            overflow: hidden;
            text-align: center;
            width: 100%;
            h3 {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px; }
            h4 {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px; } }
        .conteudo {
            margin-top: 60px;
            padding: 10px 5% 0;
            height: 290px;
            overflow-y: auto;
            li {
                font-size: 14px;
                line-height: 20px; } }

        .footer {
            width: 100%;
            height: 50px;
            padding: 10px 0;
            position: absolute;
            bottom: 0;
            left: 0;
            .buttons {
                width: 165px;
                margin: 0 auto;
                button {
                    width: 80px;
                    height: 25px;
                    background: $c-light-brown none repeat scroll 0% 0%;
                    color: $c-light;
                    border: 1px solid $c-mid-brown;
                    font-size: 12px;
                    text-align: center;
                    font-weight: 700;
                    text-transform: uppercase; } } } }
    &.compact {
        height: 120px;
        margin-top: -60px;
        .header {
            height: 40px;
            h4 {
                display: none; } }
        .conteudo {
            display: none; } }

    &:before {
        content: '';
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        display: block;
        top: 0;
        left: 0;
        z-index: 8; } }

.wrap-modal {
    display: none;
    position: fixed;
    background: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9; }
