[data-reordable] {
    thead {

        td {
            color: $c-mid-gray-2 !important;
            text-align: center !important; }

        tr.tabled-row.tabled-header th.disable-sorter {
            background-image: none !important;
            cursor: default !important; }
        tr {
            th.file-check, th.file-actions {
                background: none !important; }
            th.file-check {
                width: 30px; }
            th:not(.file-check, .file-actions) {
                background-position: -5px !important;
                padding-left: 15px !important;
                background-image: url("/static/images/table-order-arrows/bg.png") !important;

                &.headerSortDown, &.headerSortUp {
                    font-weight: 700 !important; }
                &.headerSortDown {
                    background-image: url("/static/images/table-order-arrows/desc.png") !important; }
                &.headerSortUp {
                    background-image: url("/static/images/table-order-arrows/asc.png") !important; } } } }
    tbody {
        td.file-check {
            width: 30px; } } }
