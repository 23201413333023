.content-form {
    &.images {
        .ui-sortable {
            overflow: hidden;
            .ui-sortable-handle {
                cursor: move;
                background: $c-lighter;
                height: 173px;
                width: 20%;
                float: left;
                border: none; }
            .file-name-linked {
                height: 100%;
                width: 100%;
                img {
                    max-height: 100%; } }
            .file-pendent-item {
                .order {
                    text-align: left;
                    width: 100%;
                    height: 100%;
                    border: none;
                    font-size: 24px;
                    color: $c-light-brown; } } } } }

