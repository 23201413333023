.content-form {
    &.projects {
        .file-chosen-list {
            margin: 0;
            max-width: 100%;
            max-height: 70px;
            overflow-y: auto;
            li {
                line-height: 1.5em;
                color: $c-mid-gray;
                font-size: 0.8em;
                text-transform: lowercase;
                padding-left: 5px;
                &.file-computer {
                    border: 1px solid $c-lighter-gray;
                    position: relative;
                    .remove-image {
                        position: absolute;
                        right: 10px; } } }
            a {
                font-size: 1em;
                &.remove-image {
                    color: $c-mid-gray;
                    font-size: 21px;
                    font-weight: 700;
                    padding: 0px;
                    margin-left: 5px; } }

            .add-images {
                @include button-return;
                text: {
                    align: center;
                    transform: capitalize; } } }
        .file-actions {
            text-align: center !important;
            a {
                color: $c-light-brown;
                text-decoration: none;
                font: {
                    size: 1.5em;
                    weight: bold; }
                padding: 0 5%; } } }

    &.executive-drawing {
        .check-list, #id_origin {
            li {
                font-size: 0.8em;
                padding: 0.5% 0;
                border: {
                    top: 1px dashed $c-lighter-gray; }
                color: $c-mid-gray;
                label {
                    cursor: pointer; } } }
        .check-list {
            li {
                border: none; } } } }
