.file-choose-modal {
    .modal {
        @extend .button-file;
        padding: 35px;
        position: absolute;
        height: 145px;
        width: 500px;
        left: 0;
        right: 0;
        background-color: $c_light;
        border: 1px solid $c_light_gray;
        margin: -72px auto 0;
        top: 50%;
        bottom: 50%;
        input[type="file"] {
            display: none; }
        img.from-extranet {
            float: right;
            cursor: pointer;
            display: inline-block;
            margin-top: 5px; }
        img.from-computer {
            width: 110px;
            float: left;
            cursor: pointer;
            display: inline-block;
            height: auto;
            margin-top: -14px;
            margin-left: 10px;
            max-height: 300px; } } }


.file-choose-modal-system {
    .modal {
        position: fixed;
        top: 30px;
        bottom: 30px;
        left: 30px;
        right: 30px;
        overflow: auto;
        .content-form {
            overflow-y: auto; } } }
